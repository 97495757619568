// Variables
/*@import "variables";*/

$bg_color: #3B0C1C;
$text_color: #ffffff;
$light_gold: #E5D3B0;
$dark_text_gold: #966B45;
$dark_red: #501026;
$dark_red2: #60122D;
$dark_red3: #601236;
$fontfamily: "Noto Sans TC", "PingHei", "PingFang TC", "Microsoft JhengHei", "Microsoft YaHei","STHeitiTC-Light", "Arimo", Arial, Helvetica, sans-serif;

// Bootstrap
$container-max-widths: (
  sm: 540px,
  md: 768px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
);
/*$grid-gutter-width: 90px;*/
@import "bootstrap/scss/functions";
@import "bootstrap/scss/bootstrap";

$hamburger-layer-width: 10px;
$hamburger-layer-height: 2px;
$hamburger-layer-spacing: 2px;
$hamburger-layer-color: #ffffff;
$hamburger-padding-x: 3px;
$hamburger-padding-y: 3px;

@import "hamburgers/hamburgers";

@import "global";

@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?kbnxm5');
  src:  url('fonts/icomoon.eot?kbnxm5#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?kbnxm5') format('truetype'),
    url('fonts/icomoon.woff?kbnxm5') format('woff'),
    url('fonts/icomoon.svg?kbnxm5#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-facebook-square:before {
  content: "\f082";
}
.icon-instagram1:before {
  content: "\f16d";
}
.icon-fax:before {
  content: "\f1ac";
}
.icon-wechat:before {
  content: "\f1d7";
}
.icon-weixin:before {
  content: "\f1d7";
}
.icon-whatsapp:before {
  content: "\f232";
}
.icon-youtube1:before {
  content: "\ea9d";
}
.icon-activity:before {
  content: "\e900";
}
.icon-airplay:before {
  content: "\e901";
}
.icon-alert-circle:before {
  content: "\e902";
}
.icon-alert-octagon:before {
  content: "\e903";
}
.icon-alert-triangle:before {
  content: "\e904";
}
.icon-align-center:before {
  content: "\e905";
}
.icon-align-justify:before {
  content: "\e906";
}
.icon-align-left:before {
  content: "\e907";
}
.icon-align-right:before {
  content: "\e908";
}
.icon-anchor:before {
  content: "\e909";
}
.icon-aperture:before {
  content: "\e90a";
}
.icon-archive:before {
  content: "\e90b";
}
.icon-arrow-down:before {
  content: "\e90c";
}
.icon-arrow-down-circle:before {
  content: "\e90d";
}
.icon-arrow-down-left:before {
  content: "\e90e";
}
.icon-arrow-down-right:before {
  content: "\e90f";
}
.icon-arrow-left:before {
  content: "\e910";
}
.icon-arrow-left-circle:before {
  content: "\e911";
}
.icon-arrow-right:before {
  content: "\e912";
}
.icon-arrow-right-circle:before {
  content: "\e913";
}
.icon-arrow-up:before {
  content: "\e914";
}
.icon-arrow-up-circle:before {
  content: "\e915";
}
.icon-arrow-up-left:before {
  content: "\e916";
}
.icon-arrow-up-right:before {
  content: "\e917";
}
.icon-at-sign:before {
  content: "\e918";
}
.icon-award:before {
  content: "\e919";
}
.icon-bar-chart:before {
  content: "\e91a";
}
.icon-bar-chart-2:before {
  content: "\e91b";
}
.icon-battery:before {
  content: "\e91c";
}
.icon-battery-charging:before {
  content: "\e91d";
}
.icon-bell:before {
  content: "\e91e";
}
.icon-bell-off:before {
  content: "\e91f";
}
.icon-bluetooth:before {
  content: "\e920";
}
.icon-bold:before {
  content: "\e921";
}
.icon-book:before {
  content: "\e922";
}
.icon-book-open:before {
  content: "\e923";
}
.icon-bookmark:before {
  content: "\e924";
}
.icon-box:before {
  content: "\e925";
}
.icon-briefcase:before {
  content: "\e926";
}
.icon-calendar:before {
  content: "\e927";
}
.icon-camera:before {
  content: "\e928";
}
.icon-camera-off:before {
  content: "\e929";
}
.icon-cast:before {
  content: "\e92a";
}
.icon-check:before {
  content: "\e92b";
}
.icon-check-circle:before {
  content: "\e92c";
}
.icon-check-square:before {
  content: "\e92d";
}
.icon-chevron-down:before {
  content: "\e92e";
}
.icon-chevron-left:before {
  content: "\e92f";
}
.icon-chevron-right:before {
  content: "\e930";
}
.icon-chevron-up:before {
  content: "\e931";
}
.icon-chevrons-down:before {
  content: "\e932";
}
.icon-chevrons-left:before {
  content: "\e933";
}
.icon-chevrons-right:before {
  content: "\e934";
}
.icon-chevrons-up:before {
  content: "\e935";
}
.icon-chrome:before {
  content: "\e936";
}
.icon-circle:before {
  content: "\e937";
}
.icon-clipboard:before {
  content: "\e938";
}
.icon-clock:before {
  content: "\e939";
}
.icon-cloud:before {
  content: "\e93a";
}
.icon-cloud-drizzle:before {
  content: "\e93b";
}
.icon-cloud-lightning:before {
  content: "\e93c";
}
.icon-cloud-off:before {
  content: "\e93d";
}
.icon-cloud-rain:before {
  content: "\e93e";
}
.icon-cloud-snow:before {
  content: "\e93f";
}
.icon-code:before {
  content: "\e940";
}
.icon-codepen:before {
  content: "\e941";
}
.icon-codesandbox:before {
  content: "\e942";
}
.icon-coffee:before {
  content: "\e943";
}
.icon-columns:before {
  content: "\e944";
}
.icon-command:before {
  content: "\e945";
}
.icon-compass:before {
  content: "\e946";
}
.icon-copy:before {
  content: "\e947";
}
.icon-corner-down-left:before {
  content: "\e948";
}
.icon-corner-down-right:before {
  content: "\e949";
}
.icon-corner-left-down:before {
  content: "\e94a";
}
.icon-corner-left-up:before {
  content: "\e94b";
}
.icon-corner-right-down:before {
  content: "\e94c";
}
.icon-corner-right-up:before {
  content: "\e94d";
}
.icon-corner-up-left:before {
  content: "\e94e";
}
.icon-corner-up-right:before {
  content: "\e94f";
}
.icon-cpu:before {
  content: "\e950";
}
.icon-credit-card:before {
  content: "\e951";
}
.icon-crop:before {
  content: "\e952";
}
.icon-crosshair:before {
  content: "\e953";
}
.icon-database:before {
  content: "\e954";
}
.icon-delete:before {
  content: "\e955";
}
.icon-disc:before {
  content: "\e956";
}
.icon-dollar-sign:before {
  content: "\e957";
}
.icon-download:before {
  content: "\e958";
}
.icon-download-cloud:before {
  content: "\e959";
}
.icon-droplet:before {
  content: "\e95a";
}
.icon-edit:before {
  content: "\e95b";
}
.icon-edit-2:before {
  content: "\e95c";
}
.icon-edit-3:before {
  content: "\e95d";
}
.icon-external-link:before {
  content: "\e95e";
}
.icon-eye:before {
  content: "\e95f";
}
.icon-eye-off:before {
  content: "\e960";
}
.icon-facebook:before {
  content: "\e961";
}
.icon-fast-forward:before {
  content: "\e962";
}
.icon-feather:before {
  content: "\e963";
}
.icon-figma:before {
  content: "\e964";
}
.icon-file:before {
  content: "\e965";
}
.icon-file-minus:before {
  content: "\e966";
}
.icon-file-plus:before {
  content: "\e967";
}
.icon-file-text:before {
  content: "\e968";
}
.icon-film:before {
  content: "\e969";
}
.icon-filter:before {
  content: "\e96a";
}
.icon-flag:before {
  content: "\e96b";
}
.icon-folder:before {
  content: "\e96c";
}
.icon-folder-minus:before {
  content: "\e96d";
}
.icon-folder-plus:before {
  content: "\e96e";
}
.icon-framer:before {
  content: "\e96f";
}
.icon-frown:before {
  content: "\e970";
}
.icon-gift:before {
  content: "\e971";
}
.icon-git-branch:before {
  content: "\e972";
}
.icon-git-commit:before {
  content: "\e973";
}
.icon-git-merge:before {
  content: "\e974";
}
.icon-git-pull-request:before {
  content: "\e975";
}
.icon-github:before {
  content: "\e976";
}
.icon-gitlab:before {
  content: "\e977";
}
.icon-globe:before {
  content: "\e978";
}
.icon-grid:before {
  content: "\e979";
}
.icon-hard-drive:before {
  content: "\e97a";
}
.icon-hash:before {
  content: "\e97b";
}
.icon-headphones:before {
  content: "\e97c";
}
.icon-heart:before {
  content: "\e97d";
}
.icon-help-circle:before {
  content: "\e97e";
}
.icon-hexagon:before {
  content: "\e97f";
}
.icon-home:before {
  content: "\e980";
}
.icon-image:before {
  content: "\e981";
}
.icon-inbox:before {
  content: "\e982";
}
.icon-info:before {
  content: "\e983";
}
.icon-instagram:before {
  content: "\e984";
}
.icon-italic:before {
  content: "\e985";
}
.icon-key:before {
  content: "\e986";
}
.icon-layers:before {
  content: "\e987";
}
.icon-layout:before {
  content: "\e988";
}
.icon-life-buoy:before {
  content: "\e989";
}
.icon-link:before {
  content: "\e98a";
}
.icon-link-2:before {
  content: "\e98b";
}
.icon-linkedin:before {
  content: "\e98c";
}
.icon-list:before {
  content: "\e98d";
}
.icon-loader:before {
  content: "\e98e";
}
.icon-lock:before {
  content: "\e98f";
}
.icon-log-in:before {
  content: "\e990";
}
.icon-log-out:before {
  content: "\e991";
}
.icon-mail:before {
  content: "\e992";
}
.icon-map:before {
  content: "\e993";
}
.icon-map-pin:before {
  content: "\e994";
}
.icon-maximize:before {
  content: "\e995";
}
.icon-maximize-2:before {
  content: "\e996";
}
.icon-meh:before {
  content: "\e997";
}
.icon-menu:before {
  content: "\e998";
}
.icon-message-circle:before {
  content: "\e999";
}
.icon-message-square:before {
  content: "\e99a";
}
.icon-mic:before {
  content: "\e99b";
}
.icon-mic-off:before {
  content: "\e99c";
}
.icon-minimize:before {
  content: "\e99d";
}
.icon-minimize-2:before {
  content: "\e99e";
}
.icon-minus:before {
  content: "\e99f";
}
.icon-minus-circle:before {
  content: "\e9a0";
}
.icon-minus-square:before {
  content: "\e9a1";
}
.icon-monitor:before {
  content: "\e9a2";
}
.icon-moon:before {
  content: "\e9a3";
}
.icon-more-horizontal:before {
  content: "\e9a4";
}
.icon-more-vertical:before {
  content: "\e9a5";
}
.icon-mouse-pointer:before {
  content: "\e9a6";
}
.icon-move:before {
  content: "\e9a7";
}
.icon-music:before {
  content: "\e9a8";
}
.icon-navigation:before {
  content: "\e9a9";
}
.icon-navigation-2:before {
  content: "\e9aa";
}
.icon-octagon:before {
  content: "\e9ab";
}
.icon-package:before {
  content: "\e9ac";
}
.icon-paperclip:before {
  content: "\e9ad";
}
.icon-pause:before {
  content: "\e9ae";
}
.icon-pause-circle:before {
  content: "\e9af";
}
.icon-pen-tool:before {
  content: "\e9b0";
}
.icon-percent:before {
  content: "\e9b1";
}
.icon-phone:before {
  content: "\e9b2";
}
.icon-phone-call:before {
  content: "\e9b3";
}
.icon-phone-forwarded:before {
  content: "\e9b4";
}
.icon-phone-incoming:before {
  content: "\e9b5";
}
.icon-phone-missed:before {
  content: "\e9b6";
}
.icon-phone-off:before {
  content: "\e9b7";
}
.icon-phone-outgoing:before {
  content: "\e9b8";
}
.icon-pie-chart:before {
  content: "\e9b9";
}
.icon-play:before {
  content: "\e9ba";
}
.icon-play-circle:before {
  content: "\e9bb";
}
.icon-plus:before {
  content: "\e9bc";
}
.icon-plus-circle:before {
  content: "\e9bd";
}
.icon-plus-square:before {
  content: "\e9be";
}
.icon-pocket:before {
  content: "\e9bf";
}
.icon-power:before {
  content: "\e9c0";
}
.icon-printer:before {
  content: "\e9c1";
}
.icon-radio:before {
  content: "\e9c2";
}
.icon-refresh-ccw:before {
  content: "\e9c3";
}
.icon-refresh-cw:before {
  content: "\e9c4";
}
.icon-repeat:before {
  content: "\e9c5";
}
.icon-rewind:before {
  content: "\e9c6";
}
.icon-rotate-ccw:before {
  content: "\e9c7";
}
.icon-rotate-cw:before {
  content: "\e9c8";
}
.icon-rss:before {
  content: "\e9c9";
}
.icon-save:before {
  content: "\e9ca";
}
.icon-scissors:before {
  content: "\e9cb";
}
.icon-search:before {
  content: "\e9cc";
}
.icon-send:before {
  content: "\e9cd";
}
.icon-server:before {
  content: "\e9ce";
}
.icon-settings:before {
  content: "\e9cf";
}
.icon-share:before {
  content: "\e9d0";
}
.icon-share-2:before {
  content: "\e9d1";
}
.icon-shield:before {
  content: "\e9d2";
}
.icon-shield-off:before {
  content: "\e9d3";
}
.icon-shopping-bag:before {
  content: "\e9d4";
}
.icon-shopping-cart:before {
  content: "\e9d5";
}
.icon-shuffle:before {
  content: "\e9d6";
}
.icon-sidebar:before {
  content: "\e9d7";
}
.icon-skip-back:before {
  content: "\e9d8";
}
.icon-skip-forward:before {
  content: "\e9d9";
}
.icon-slack:before {
  content: "\e9da";
}
.icon-slash:before {
  content: "\e9db";
}
.icon-sliders:before {
  content: "\e9dc";
}
.icon-smartphone:before {
  content: "\e9dd";
}
.icon-smile:before {
  content: "\e9de";
}
.icon-speaker:before {
  content: "\e9df";
}
.icon-square:before {
  content: "\e9e0";
}
.icon-star:before {
  content: "\e9e1";
}
.icon-stop-circle:before {
  content: "\e9e2";
}
.icon-sun:before {
  content: "\e9e3";
}
.icon-sunrise:before {
  content: "\e9e4";
}
.icon-sunset:before {
  content: "\e9e5";
}
.icon-tablet:before {
  content: "\e9e6";
}
.icon-tag:before {
  content: "\e9e7";
}
.icon-target:before {
  content: "\e9e8";
}
.icon-terminal:before {
  content: "\e9e9";
}
.icon-thermometer:before {
  content: "\e9ea";
}
.icon-thumbs-down:before {
  content: "\e9eb";
}
.icon-thumbs-up:before {
  content: "\e9ec";
}
.icon-toggle-left:before {
  content: "\e9ed";
}
.icon-toggle-right:before {
  content: "\e9ee";
}
.icon-tool:before {
  content: "\e9ef";
}
.icon-trash:before {
  content: "\e9f0";
}
.icon-trash-2:before {
  content: "\e9f1";
}
.icon-trello:before {
  content: "\e9f2";
}
.icon-trending-down:before {
  content: "\e9f3";
}
.icon-trending-up:before {
  content: "\e9f4";
}
.icon-triangle:before {
  content: "\e9f5";
}
.icon-truck:before {
  content: "\e9f6";
}
.icon-tv:before {
  content: "\e9f7";
}
.icon-twitch:before {
  content: "\e9f8";
}
.icon-twitter:before {
  content: "\e9f9";
}
.icon-type:before {
  content: "\e9fa";
}
.icon-umbrella:before {
  content: "\e9fb";
}
.icon-underline:before {
  content: "\e9fc";
}
.icon-unlock:before {
  content: "\e9fd";
}
.icon-upload:before {
  content: "\e9fe";
}
.icon-upload-cloud:before {
  content: "\e9ff";
}
.icon-user:before {
  content: "\ea00";
}
.icon-user-check:before {
  content: "\ea01";
}
.icon-user-minus:before {
  content: "\ea02";
}
.icon-user-plus:before {
  content: "\ea03";
}
.icon-user-x:before {
  content: "\ea04";
}
.icon-users:before {
  content: "\ea05";
}
.icon-video:before {
  content: "\ea06";
}
.icon-video-off:before {
  content: "\ea07";
}
.icon-voicemail:before {
  content: "\ea08";
}
.icon-volume:before {
  content: "\ea09";
}
.icon-volume-1:before {
  content: "\ea0a";
}
.icon-volume-2:before {
  content: "\ea0b";
}
.icon-volume-x:before {
  content: "\ea0c";
}
.icon-watch:before {
  content: "\ea0d";
}
.icon-wifi:before {
  content: "\ea0e";
}
.icon-wifi-off:before {
  content: "\ea0f";
}
.icon-wind:before {
  content: "\ea10";
}
.icon-x:before {
  content: "\ea11";
}
.icon-x-circle:before {
  content: "\ea12";
}
.icon-x-octagon:before {
  content: "\ea13";
}
.icon-x-square:before {
  content: "\ea14";
}
.icon-youtube:before {
  content: "\ea15";
}
.icon-zap:before {
  content: "\ea16";
}
.icon-zap-off:before {
  content: "\ea17";
}
.icon-zoom-in:before {
  content: "\ea18";
}
.icon-zoom-out:before {
  content: "\ea19";
}

@font-face {
  font-family: 'Material-Design-Iconic-Font';
  src: url('fonts/Material-Design-Iconic-Font.woff2?v=2.2.0') format('woff2'), url('fonts/Material-Design-Iconic-Font.woff?v=2.2.0') format('woff'), url('fonts/Material-Design-Iconic-Font.ttf?v=2.2.0') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
    font-family: 'Hoefler Text';
    src: url('fonts/HoeflerText.eot');
    src: url('fonts/HoeflerText.eot?#iefix') format('embedded-opentype'),
        url('fonts/HoeflerText.woff2') format('woff2'),
        url('fonts/HoeflerText.woff') format('woff'),
        url('fonts/HoeflerText.ttf') format('truetype'),
        url('fonts/HoeflerText.svg#HoeflerText') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.wrapper{
    /*width:100%;
    max-width:1400px;*/
    /*overflow:hidden;*/
    width:100%;
    /*position:relative;*/
    margin:auto;
    background: $bg_color;
}

.sm-break{
    display:none;
    text-indent:-9999px;
    @media (max-width: #{$mobile-width}) {
        display:block;
        line-height:0;
    }
}

.md-break{
    @media (min-width: #{$mobile-width}) and (max-width: #{$tablet-width}) {
        display:block;
        line-height:0;
    }
}

.lg-break{
    @media (min-width: #{$desktop-width}) {
        display:block;
        line-height:0;
    }
}

#loader{
    position:fixed;
    width:100%;
    height:100%;
    left:0;
    top:0;
    z-index:999;
    background:#e0c8c4;
    background:url('../images/hey_pattern.jpg') repeat;
    /*display:none;*/
}

html {
    font-size:12px;
    @media (min-width: 400px) {
    }
    @include tablet {
    }
    @include mid-desktop{
        font-size:14px;
    }
}

body {
    background: $bg_color;
    color: $text_color;
    font-family: $fontfamily;
    overflow-x:hidden;
}
html, body {
    width:100%;
    height: auto;
}

header{
    position:fixed;
    left:0;
    top:0;
    z-index:20;
    width:100%;
    padding:30px 0 0;
    min-height:90px;
    /*pointer-events:none;*/
    transition:all .75s ease-in-out;
    @media (max-width: #{$desktop-width - 1}) {
        background:$dark_red;
        padding:15px 0;
        position:sticky;
        .bar-flex{
            .logo{
                width:90px !important;
            }
        }
    }
    @include desktop{
        padding:60px 0 0;
    }
    &.mini-size{
        background:$dark_red;
        padding:15px 0;
        .bar-flex{
            .logo{
                width:100px;
            }
        }
    }
    .bar-flex{
        padding:0 30px;
        display:flex;
        justify-content:space-between;
        /*align-items:flex-start;*/
        align-items:center;
        @include desktop{
            padding:0 55px;
        }
        .logo{
            display:inline-block;
            background-image:url('../images/golden_logo_shadow@2x.png');
            background-repeat:no-repeat;
            background-size:cover;
            transition:all .75s ease-in-out;
            @include tablet{
                width:155px;
            }
            @include desktop{
                width:174px;
            }
            img{
                opacity:0;
                width:100%;
            }
        }
        >div{
            /*pointer-events:all;*/
        }
    }

    .action-bar{
        display:flex;
        align-items:flex-end;
        flex-direction:column-reverse;
        @include desktop{
            flex-direction:row;
            align-items:center;
        }
        .lang{
            margin:26px 0 0;
            display:none;
            @include desktop{
                margin:0;
                display:unset;
            }
            li{
                position: relative;
                font-size:1rem;
                display:inline-block;
                margin:0 4px;
                vertical-align:top;
                a{
                    color:#ffffff;
                    opacity:1;
                    transition:opacity .4s;
                    &:hover{
                        opacity:.7;
                    }
                }
            }
            .current-lang{
                &:after{
                    content:"";
                    position:absolute;
                    left:0;
                    bottom:-5px;
                    width:100%;
                    height:2px;
                    background:#ffffff;
                }
            }
        }
        .button{
            display:flex;
            align-items:center;
            margin-left:10px;
        }
        .function-icon{
            font-size:18px;
            line-height:1;
            vertical-align: middle;
            position:relative;
            padding:6px 6px;
            color:#ffffff;
            transition:opacity .4s;
            &:hover{
                color:#ffffff;
                opacity:.8;
            }
        }
        .cart-icon{
            margin:0;
            &:not(.has-items){
                .count{
                    display:none;
                }
            }
            .count{
                font-size:10px;
                position:absolute;
                right:0;
                top:0;
                width:14px;
                height:14px;
                display:flex;
                align-items:center;
                justify-content: center;
                background:#000000;
                color:#ffffff;
                border-radius:50%;
            }
        }
        button.hamburger{
            outline:none !important;
            line-height:1;
            width:26px;
            height:26px;
            border:2px solid #ffffff;
            border-radius:50%;
            margin:0 0 0 10px;
            @include desktop{
                display:none;
            }
        }
    }
}

.title-font{
    font-family:"Hoefler Text", "Noto Serif TC";
}

.container{
    padding-left:30px;
    padding-right:30px;
}

.home-section-slider{
    width:100%;
    height:100vh;
    height: calc(var(--vh, 1vh) * 100);
    position:relative;
    background:$dark_red;
    @media (max-width: #{$desktop-width - 1}) {
        display:none;
    }
    .gallery-placer{
        position:absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;
    }
    .upper-layer{
        position:absolute;
        z-index:5;
        width:100%;
        height:100%;
        display:flex;
        align-items:center;
        justify-content:center;
        color:$light_gold;
        >div{
            width:100%;
            text-align:center;
        }
        h3{
            font-size:29px;
            letter-spacing:.2em;
            line-height:2;
        }
    }
    .swiper-container{
        height:100%;
    }
    .swiper-slide{
        position:relative;
        height:100%;
        &:before{
            content:"";
            position:absolute;
            left:0;
            top:0;
            width:100%;
            height:100%;
            pointer-events:none;
            background:url('../images/overlay.png') left top repeat;
            opacity:.7;
        }
        img{
            width:100%;
            height:100%;
            object-fit:cover;
            opacity:1;
        }
    }
    .action-bar{
        position:absolute;
        left:0;
        bottom:0;
        width:100%;
        z-index:10;
        .block-category{
            height:200px;
            padding-top:30px;
            padding-bottom:30px;
            text-align:center;
            display:flex;
            align-items:center;
            justify-content:center;
            text-align:center;
            color:$dark_text_gold;
            font-size:1rem;
            letter-spacing:.2em;
            &:before{
                content:"";
                width:1px;
                height:calc(100% + 40px);
                background:#82622B;
                position:absolute;
                left:0;
                bottom:0;
            }
            &:first-child{
                background:$dark_red;
                &:before{
                    content:none;
                }
            }
            &:nth-child(2){
                background:$dark_red2;
            }
            &:nth-child(3){
                background:$dark_red3;
            }
            h6{
                font-size:1.3em;
                margin:.8em 0 1.2em;
                letter-spacing:.2em;
            }
            .border-btn{
                display:inline-block;
                color:$dark_text_gold;
                padding:2px 30px;
                border:1px solid $dark_text_gold;
                border-radius:50px;
                font-size:.8em;
                line-height:2;
            }
        }
    }
}


#menu{
    background:$dark_red;
    position:fixed;
    z-index:15;
    width:100%;
    height:100vh;
    height: calc(var(--vh, 1vh) * 100);
    right:0;
    top:0;
    @media (max-width: #{$desktop-width - 1}) {
        transform:translate(0, -100%);
    }
    @include desktop{
        right:100%;
        top:0;
        width:330px;
        height:100vh;
        height: calc(var(--vh, 1vh) * 100);
        z-index:25;
        opacity:1 !important;
    }
    .wrap-content{
        padding:80px 30px 40px;
        @include desktop{
            padding:60px 55px 180px;
            height:100%;
        }
        .logo{
            display:none;
            width:120px;
            background-image:url('../images/golden_logo_shadow@2x.png');
            background-repeat:no-repeat;
            background-size:cover;
            @include desktop{
                width:174px;
                display:inline-block;
            }
            img{
                opacity:0;
                width:100%;
            }
        }
        .lang{
            margin:10px 0 0;
            text-align:right;
            @include desktop{
                margin:0;
                display:none;
            }
            li{
                position: relative;
                font-size:1rem;
                display:inline-block;
                margin:0 4px;
                vertical-align:top;
                a{
                    color:#ffffff;
                    opacity:1;
                    transition:opacity .4s;
                    &:hover{
                        opacity:.7;
                    }
                }
            }
            .current-lang{
                &:after{
                    content:"";
                    position:absolute;
                    left:0;
                    bottom:-5px;
                    width:100%;
                    height:2px;
                    background:#ffffff;
                }
            }
        }
        .space-between{
            margin-top:40px;
            @include desktop{
                height:100%;
                margin:0;
                display:flex;
                align-items:center;
            }
            >div{
                width:100%;
            }
        }
        .page-list{
            font-size:0;
            li{
                font-size:1rem;
                letter-spacing:.2em;
                margin:14px 0 0;
                display:inline-block;
                width:50%;
                &:nth-child(2n + 3){
                    @include desktop{
                        text-align:right;
                    }
                }
                a{
                    color:#707070;
                    transition:opacity .4s;
                    &:hover{
                        opacity:.7;
                    }
                }
            }
            li.index{
                width:100%;
                a{
                    color:$dark_text_gold;
                }
            }
        }
        .contact-block{
            color:#707070;
            border-top:1px solid $dark_text_gold;
            padding:8vh 0;
            margin:8vh 0 0;
            font-size:12px;
            letter-spacing:.2em;
            @include desktop{
                padding:30% 0;
                margin:30% 0 0;
                border-bottom:1px solid $dark_text_gold;
            }
            .h6{
                display:inline-block;
                color:$dark_text_gold;
                margin-bottom:1rem;
                font-weight:400;
            }
            a{
                color:inherit;
                transition:opacity .4s;
                &:hover{
                    opacity:.7;
                }
            }
            .minor{
                letter-spacing:.1em;
            }
            .icon-phone, .icon-whatsapp{
                width:18px;
                display:inline-block;
            }
        }
    }
    .dialog-button-spacing{
        display:none;
        position:absolute;
        width:16px;
        height:100%;
        left:calc(100% - 1px);
        top:0;
        background:$dark_red;
        @include desktop{
            display:block;
        }
    }
    .dialog-button{
        position:absolute;
        left:calc(100% - 1px);
        top:28%;
        display:block;
        height:250px;
        padding:60px 0 44px;
        width:40px;
        background:$dark_red;
        font-size:20px;
        letter-spacing:16px;
        color:$dark_text_gold;
        writing-mode: vertical-rl;
        display:flex;
        justify-content:center;
        align-items:center;
        span{

        }
        .dot{
            width:8px;
            height:8px;
            background:#3B0C1C;
            border-radius:50%;
            position:absolute;
            left:50%;
            transform:translateX(-50%);
            &:before{
                content:"";
                width:1px;
                height:999px;
                background:#89181D;
                position:absolute;
                left:3px;
            }
        }
        .top{
            top:22px;
            &:before{
                bottom:3px;
            }
        }
        .btm{
            bottom:22px;
            &:before{
                top:3px;
            }
        }
    }
}

.hey-pattern{
    background:url('../images/hey_pattern.jpg') repeat;
}

main{
    section{
        padding:6.25rem 0;
    }
    .first-section-padding{
        padding-top:160px;
        @include tablet{
            padding-top:250px;
        }
        
    }
}

.section-title{
    font-size:1.5rem;
    letter-spacing:.2em;
    color:#956B48;
    @extend .title-font;
}

.tc-title{
    @include desktop{
        letter-spacing:.7em;    
    }
}

.cms-content{
    line-height:2;
    p{
        margin-bottom:1.35rem;
        letter-spacing:.2em;
        &:last-child{
            margin-bottom:0;
        }
    }
}

#aboutus{
    .about-sharon{
        text-align:center;
        color:$dark_text_gold;
        padding-bottom:60px;
        h4.section-title{
            margin-bottom:1rem;
        }
        .about-sharon-img{
            margin:40px 0 0;
        }
        .about-cms-content{
            width:100%;
            max-width:850px;
            margin:0 auto;
            h4.section-title{
                margin:60px 0 40px;
            }
        }
    }
    .about-partnership{
        text-align:center;
        background:#ffffff;
        .logo-list{
            display:flex;
            align-items:center;
            flex-wrap: wrap;
            margin:60px -50px 0;
            >div{
                flex-grow:1;
                margin:40px 50px;
            }
        }
    }
    .about-awards{
        background:#f9f9f9;
        h4.section-title{
            text-align:center;
        }
        .awards-blk{
            display:flex;
            align-items:center;
            color:$dark_text_gold;
            font-size:1rem;
            letter-spacing:.1em;
        }
        .icon-img{
            flex:0 0 128px;
            padding-right:12px;
        }
        .row{
            >div{
                margin-top:40px;
            }
        }
    }
}

#chinese-wedding{

}

.std-button{
    display:inline-block;
    border:1px solid #DD985B;
    border-radius:40px;
    color:#DD985B;
    padding:.75rem 1.2rem;
    font-size:.9rem;
    letter-spacing:.2em;
    transition:all .4s;
    &:hover{
        background:#DD985B;
        color:#ffffff;
    }
}

.minor-letter-spacing{
    letter-spacing:.1em;
}

.chinese-wedding-style{
    color:#A5A5A5;
    .anchor{
        @include tablet{
            top:calc(-250px - 5px);
        }
    }
    p{
        letter-spacing:.2em;
    }
    .size-limiter{
        @include mid-desktop{
            max-width:1200px;
        }
    }
    .title-label{
        
        white-space:nowrap;
        
        left:0;
        top:200px;
        z-index:1;
        @include desktop{
            writing-mode:vertical-rl;
            position:sticky;
        }
        .sticky-elem{
            top:0;
            left:0;
            padding-right:10px;
            @include desktop{
                position:absolute;
            }
            &:after{
                content:"";
                position:absolute;
                background:#871A21;
                box-shadow:0 0 1px #000000;
                @include desktop{
                    width:1px;
                    height:30vh;
                    left:50%;
                    top:calc(100% + 20px);
                }
            }
        }
        h2{
            font-size:2.5rem;
            letter-spacing:1em;
            color:$dark_text_gold;
            @extend .title-font;
            @include mid-desktop{
                font-size:3rem;
            }
        }
    }
    .page-section-list{
        width:160px;
        position:sticky;
        right:0;
        top:200px;
        float:right;
        z-index:1;
        display:none;
        @include desktop{
            display:block;
        }
        @include mid-desktop{
            width:200px;
        }
        .sticky-elem{
            position:absolute;
            right:0;
            top:0;
            width:100%;
        }
        .border-placer{
            position:relative;
            .topline, .btmline{
                position:absolute;
                width:100%;
                height:1px;
                background:#871A21;
                transition:top .6s ease-in-out;
            }
        }
        ul{
            font-size:0;
            li{
                display:block;
                text-align:center;
                font-size:1rem;
                letter-spacing: .2em;
                margin:0;
                padding:0;
                a{
                    display:block;
                    padding:1rem 1rem;
                    color:$dark_text_gold;
                    transition:opacity .4s;
                    &:hover{
                        opacity:.7;
                    }
                }
            }
        }
    }
    .section{
        position:relative;
        margin:26vh 0 0;
    }
    .free-area{
        position:relative;
        
        @include desktop{
            padding-right:calc(160px + 30px);
            padding-left:100px;
        }
        @include mid-desktop{
            padding-right:calc(200px + 30px);
        }
        
    }
    .trigger-line{
        min-height:1px;
        position:absolute;
        top:0;
    }
    .style-1{
        margin:10vh 0 0;
        position:relative;
        @include desktop{
            margin:0;
        }
        .image-holder{
            text-align:center;
            @include desktop{
                text-align:right;
                padding:0 0 130px;    
            }
        }
        .visual-img{
            width:75%;
            margin:auto;
            opacity:.7;
            @include desktop{
                width:65%;
            }
        }
        .text-holder{
            margin:5rem 0 0;
            @include desktop{
                position:absolute;
                width:30%;
                left:0;
                bottom:0;
                margin:0;
            }
            .section-title{
                margin-bottom:1rem;
            }
        }
    }
    .style-2{
        position:relative;
        @include mid-desktop{
            padding-left:20px;
        }
        .image-holder{
            
        }
        .text-holder{
            .row{
                margin-top:-20px;
                >div{
                    margin-top:20px;
                }
            }
        }
        .section-title{
            margin:50px 0;
        }
    }
    .style-3{
        @include mid-desktop{
            padding-left:20px;
        }
        .image-holder{
            opacity:0;
        }
        .section-title{
            margin:50px 0;
        }
    }
    .style-4{
        @include mid-desktop{
            padding-left:20px;
        }
        .intro.text-holder{
            max-width:75%;
            text-align:justify;
            .section-title{
                margin:50px 0;
            }
        }
    }
    .style-5{
        @include mid-desktop{
            padding-left:20px;
        }
    }
    .style-6{
        @include mid-desktop{
            padding-left:20px;
        }
        .photo-frame{
            .text-field{
                margin-left:20%;
                @include tablet{
                    margin-left:40%;
                }
            }
            .image-field{
                @include tablet{
                    width:68%;    
                }
            }
        }
    }
    .style-7{
        padding-bottom:10vh;
        @include tablet{
            padding-bottom:16vh;
        }
        @include mid-desktop{
            padding-left:20px;
        }
        .photo-frame{
            .text-field{
                padding-left:0;
                padding-right:60px;
                margin-left:20%;
                @include tablet{
                    margin-left:40%;
                }
                &:before{
                    left:unset;
                    right:30px;
                }
            }
        }
    }
    .sub-cat-ul{
        position:relative;
        border-bottom:1px solid #956B48;
        margin:80px 0 0;
        .overflow-container{
            position:relative;
            margin:0 40px;
            overflow:auto;
            -webkit-overflow-scrolling: touch;
            &::-webkit-scrollbar {
                display: none;
            }
        }
        .track{
            position:absolute;
            width:0;
            height:6px;
            bottom:0;
            transition:all .4s ease-in-out;
            &:before{
                content:"";
                position:absolute;
                height:100%;
                top:0;
                background:#956B48;
                width:calc(100% - 20px);
                left:50%;
                transform:translateX(-50%);
            }
        }
        ul{
            /*display:flex;*/
            padding:0;
            font-size:0;
            white-space: nowrap;
            display: inline-flex;
        }
        li{
            display:inline-block;
            padding:0;
            margin:0;
            font-size:1.1rem;
            letter-spacing:.2em;
            flex-grow:1;
            text-align:center;
            a{
                color:$dark_text_gold;
                padding:10px 20px 20px;
                display:block;
                transition:opacity .4s;
                &:hover{
                    opacity:.7;
                }
            }
        }
        .icon-chevron-left, .icon-chevron-right{
            position:absolute;
            top:9px;
            color:$dark_text_gold;
            font-size:30px;
            cursor:pointer;
            z-index:5;
        }
        .icon-chevron-left{
            left:0;
        }
        .icon-chevron-right{
            right:0;
        }
    }
    .sub-cat-content{
        margin:30px 0 0;
        min-height:1000px;
        /*.slides{
            display:none;
            &:first-child{
                display:block;
            }
        }*/
    }
    .biggift-swiper{

    }
    .package-swiper-container{
        position:relative;
        /*@include desktop{
            padding-right:calc(180px + 15px);
        }*/
        .main-swiper{
            .swiper-slide{
                width:auto;
            }
            .package-img-display{
                /*padding-top:calc(323 / 459 * 100%);
                background-repeat:no-repeat;
                background-position:center;
                background-size:cover;*/                
                img{
                    max-height:400px;
                }
            }
        }
        .thumb-swiper{
            margin:20px 0 0;
            @include desktop{
                position:absolute;
                right:0;
                top:0;
                width:180px;
                height:100%;
                margin:0;
            }
            .swiper-slide{
                opacity:.5;
            }
            .package-img-display{
                padding-top:calc(323 / 459 * 100%);
                background-repeat:no-repeat;
                background-position:center;
                background-size:cover;
                opacity:.5;
                transition:opacity .4s;
            }
            .swiper-slide-thumb-active{
                opacity:1;
            }
        }
    }
    .package-content{
        margin:80px 0 0;
        counter-reset: mycounter;
        
        .row{
            margin-top:-20px;
            >div{
                margin-top:20px;
            }
        }
        h5{
            font-size:1.2rem;
            margin-bottom:1rem;
        }
        .ps-point{
            font-size:.9rem;
            margin-top:50px;
            counter-reset: pscounter;
            li{
                &:before{
                    counter-increment: pscounter;
                    content: counter(pscounter) ". ";
                }
            }
        }
        ol{
            list-style:none;
            padding:0 0 0 5px;
            li{
                padding-left:30px;
                position:relative;
                &:before{
                    position:absolute;
                    left:0;
                    top:0;
                    counter-increment: mycounter;
                    content: counter(mycounter) ". ";
                }
            }
        }
    }
    .photo-frame{
        .text-field{
            padding-left:40px;
            padding-bottom:20px;
            max-width:400px;
            position:relative;
            z-index:1;
            @include tablet{
                padding-left:60px;
                padding-bottom:40px;
            }
            &:before{
                content:"";
                position:absolute;
                width:1px;
                height:calc(100% + 60px);
                background:#956B48;
                top:0;
                left:15px;
                @include tablet{
                    left:30px;
                }
            }
        }
        .section-title{
            margin:0 0 30px;
            @include tablet{
                margin:0 0 50px;
            }
        }
    }
}

.church-slider{
    position:fixed;
    left:0;
    top:90px;
    width:100%;
    padding:0;
    @include desktop{
        top:0;
    }
    .church-banner-swiper{
        .church-banner{
            padding-top:calc(700 / 1400 * 100%);
            position:relative;
            img{
                position:absolute;
                width:100%;
                height:100%;
                left:0;
                top:0;
                object-fit:cover;
            }
        }
    }
    .dot-layer{
        position:absolute;
        width:100%;
        height:100%;
        left:0;
        top:0;
        z-index: 1;
        background:url('../images/overlay.png') left top repeat;
    }
    .bg-layer{
        position:absolute;
        width:100%;
        height:100%;
        left:0;
        top:0;
        z-index:1;
        background:rgba(#432D03, .6);
        mix-blend-mode:exclusion;
        z-index:1;
    }
    .content-overlay{
        position:absolute;
        width:100%;
        height:100%;
        left:0;
        top:0;
        z-index:1;
        display:flex;
        align-items:center;
        justify-content:center;
        color:#ffffff;
        >div{
            text-align:center;
        }
        h5{
            font-size:1rem;
            letter-spacing:.2em;
        }
        h3{
            margin:28px 0 0;
            font-size:3rem;
            letter-spacing:1em;
            text-indent: 1em;
            @extend .title-font;
        }
    }
}

.church-intro{
    background:#EFEBE1;
    color:$dark_text_gold;
    position:relative;
    z-index:1;
    @include mid-desktop{
        padding:10rem 0;
    }
    h2.section-title{
        font-size:2.28rem;
        margin:0 0 2.5rem;
        @include desktop{
            margin:0 0 5rem;
        }
    }
    .size-limit{
        max-width:980px;
        margin:auto;
        @include tablet{
            padding:0 40px;
        }
    }
    .intro-text{
        text-align:center;
        max-width:900px;
        margin:auto;
        h2.section-title{
            font-size:2.28rem;
            margin:0 0 5rem;
            letter-spacing:.7em;
            text-indent:.7em;
        }
        .ps-small{
            font-size:.9rem;
        }
        h4.section-title{
            margin:1.5rem 0 4rem;
            letter-spacing:.7em;
            text-indent:.7em;
        }
        .cms-content{
            /*text-align:justify;*/
        }
        .cms-content + h4.section-title{
            margin-top:4rem;
        }
    }
    .church-detail-section{
        position:relative;
        margin:6.25rem 0 0;
        @include desktop{
            margin:10rem 0 0;
        }
        #church-detail-anchor{
            top:-90px;
        }
        .free-area{
            position:relative;

            padding-left:calc(200px + 30px);
        }
        .sticky-menu{
            position:sticky;
            top:90px;
            padding-bottom:60px;
            z-index:5;
            .church-submenu{
                background:#E0DACC;
                position:absolute;
                left:0;
                top:0;
                width:100%;
                .container{
                    @media (max-width: #{$desktop-width - 1}) {
                        padding-left:0;
                        padding-right:0;
                    }
                }
                .wrapall{
                    position:relative;
                }
                .overflow-container{
                    position:relative;
                    margin:0 35px;
                    text-align:center;
                    overflow:auto;
                    -webkit-overflow-scrolling: touch;
                    &::-webkit-scrollbar {
                        display: none;
                    }
                }
                .icon-chevron-left, .icon-chevron-right{
                    position:absolute;
                    top:9px;
                    color:$dark_text_gold;
                    font-size:30px;
                    cursor:pointer;
                    z-index:5;
                }
                .icon-chevron-left{
                    left:0;
                }
                .icon-chevron-right{
                    right:0;
                }
            }
            ul{
                font-size:0;
                padding:0;
                white-space:nowrap;
                display:inline-flex;
                li{
                    display:inline-block;
                    padding:15px 20px;
                    margin:0;
                    font-size:1.1rem;
                    letter-spacing:.2em;
                    flex-grow:1;
                    text-align:center;
                    position:relative;
                    &:not(:first-child):before{
                        content:"";
                        width:1px;
                        height:26px;
                        background:#ffffff;
                        position:absolute;
                        left:0;
                        top:50%;
                        transform:translateY(-50%);
                    }
                    a{
                        color:$dark_text_gold;
                        transition:opacity .4s, color .4s;
                        &:hover{
                            opacity:.7;
                        }
                    }
                }
                li.active a, a:hover{
                    color:$dark_red;
                }
            }
        }
    }
    .page-section-list{
        width:200px;
        position:sticky;
        left:0;
        top:200px;
        z-index:1;
        .border-placer{
            position:relative;
            .topline, .btmline{
                position:absolute;
                width:100%;
                height:1px;
                background:$dark_text_gold;
                transition:top .6s ease-in-out;
            }
        }
        .sticky-elem{
            position:absolute;
            top:0;
            left:0;
            width:100%;
        }
        ul{
            font-size:0;
            li{
                display:block;
                text-align:center;
                font-size:1.14rem;
                letter-spacing: .2em;
                margin:0;
                padding:0;
                a{
                    display:block;
                    padding:1rem 1rem;
                    color:$dark_text_gold;
                    transition:opacity .4s;
                    &:hover{
                        opacity:.7;
                    }
                }
            }
        }
    }
    .page-section-content{
        .wrap{
            /*padding:0 2.85rem;*/
            padding:6.25rem 0;
            @include desktop{
                padding:10rem 0;
            }
            &:not(:first-child){
                /*margin:5rem 0 0;*/
            }
        }
        .style-1{
            
        }
        .style-2{
            .image-field{
                margin:5rem 0 0;
            }
            .row{
                margin-top:-2.5rem;
                >div{
                    margin-top:2.5rem;
                }
            }
            .grass-break{
                margin:10rem 0 0;
                @include desktop{
                    margin:12rem 0 0;
                }
            }
            .size-limit .cms-content{
                max-width:700px;
            }
        }
        .style-3{
            .row{
                margin-top:30px;
                box-shadow:0 0 8px #bfbfbf;
                @include tablet{
                    margin-top:0;
                    box-shadow:none;
                }
            }
            .row:nth-child(even){
                @include tablet{
                    .text-col{
                        order:1;
                    }
                    .thumb-col{
                        order:2;
                    }
                }
            }
            .thumb-placer{
                height:100%;
                width:100%;
                padding-top:calc(100%);
                position:relative;
                background-repeat:no-repeat;
                background-size:cover;
                background-position:center;
                img{
                    position:absolute;
                    width:100%;
                    height:100%;
                    left:0;
                    top:0;
                    object-fit:cover;
                }
            }
            .text-col{
                background:#ffffff;
                text-align:center;
                letter-spacing:.2em;
                font-size:12px;
                @include tablet{
                    display:flex;
                    align-items:center;
                }
                .wrap-text{
                    width:100%;
                    padding:30px;
                    @include mid-desktop{
                        padding:40px;
                    }
                }
                .labeling{
                    font-size:1.1em;
                }
                h5{
                    font-size:1.5em;
                    margin:3rem 0 1.8rem;
                }
                h2{
                    font-size:2em;
                    margin: 0 0 3rem;
                    .small{
                        font-size:.5em;
                        letter-spacing:.2em;
                    }
                }
                h2.tc-title{
                    /*letter-spacing:.7em;
                    text-indent:.7em;*/
                }
                .set{
                    margin:3rem 0 0;
                    h6{
                        margin:0 0 1rem;
                    }
                }
            }
        }
        .style-4{
            h2.section-title{
                letter-spacing:.2em;
                line-height:1.6;
            }
            .image-field{
                margin:5rem 0 0;
            }
        }
    }
    .faq-accordion{
        letter-spacing:.2em;
        [data-control], [data-content] > *{
            padding:0;
            border:none;
        }
        [data-accordion] > [data-control]:after{
            content:none;
        }
        [data-accordion]{
            margin:1.5rem 0 0;
            line-height:inherit;
        }
        [data-accordion] [data-control]{
            padding:.5rem 0;
            cursor:pointer;
        }
        .q-title{
            font-size:1rem;
            font-weight:400;
            display:flex;
            span{
                letter-spacing:0;
                display:inline-block;
                width:40px;
                flex:0 0 40px;
            }
        }
        .q-ans{
            padding:1rem 40px;
        }
        a{
            color:$dark_red;
            transition:opacity .4s;
            &:hover{
                opacity:.7;
            }
        }
        .url-link{
            letter-spacing:0;
        }
    }
}

#wedding-service{
    .inner-banner{
        position:fixed;
        left:0;
        top:90px;
        padding:0;
        width:100%;
        @include desktop{
            top:0;
        }
        .banner-placer{
            padding-top:calc(700 / 1400 * 100%);
            background-repeat:no-repeat;
            background-position:center;
            background-size:cover;
        }
        .dot-layer{
            position:absolute;
            width:100%;
            height:100%;
            left:0;
            top:0;
            z-index: 1;
            background:url('../images/overlay.png') left top repeat;
        }
        .bg-layer{
            position:absolute;
            width:100%;
            height:100%;
            left:0;
            top:0;
            z-index:1;
            background:rgba(#432D03, .2);
            mix-blend-mode:multiply;
            z-index:1;
        }
        .content-overlay{
            position:absolute;
            width:100%;
            height:100%;
            left:0;
            top:0;
            z-index:1;
            display:flex;
            align-items:flex-end;
            justify-content:center;
            padding:0 0 3.5rem;
            >div{
                text-align:center;
            }
            h4.section-title{
                margin:1rem 0 0;
                color:$light_gold;
                text-indent:.2em;
            }
            h4.tc-title{
                text-indent:.7em;
            }
        }
    }
    .wedding-service-intro{
        background:#3E2F2F;
        position:relative;
        z-index:1;
        .intro-text{
            text-align:center;
            max-width:720px;
            margin:auto;
            color:$dark_text_gold;
            h2.section-title{
                font-size:2.28rem;
                margin:0 0 5rem;
                letter-spacing:.7em;
                text-indent:.7em;
            }
            h3{
                font-size:1.3rem;
                line-height:1.6;
                font-weight:400;
                letter-spacing:.2em;
                text-indent:.2em;
                margin:0 0 3rem;
            }
            p{
                font-size:.9rem;
            }
            .cms-content{
                /*text-align:justify;*/
            }
        }
        .big-visual-banner-holder{
            
            max-width:1400px;
            margin:6.25rem auto 0;
            @include desktop{
                padding:0 70px;
            }
        }
        .wedding-service-detail-section{
            position:relative;
            margin:6.25rem 0 0;
            @include desktop{
                margin:10rem 0 0;
            }
            .free-area{
                position:relative;
                @include desktop{
                    padding-left:calc(160px + 30px);
                }
                @include mid-desktop{
                    padding-left:calc(200px + 30px);
                }
            }
        }
        .page-section-list{
            width:160px;
            position:sticky;
            left:0;
            top:calc(10rem + 90px);
            z-index:1;
            display:none;
            @include desktop{
                display:block;
            }
            @include mid-desktop{
                width:200px;
            }
            .sticky-elem{
                position:absolute;
                right:0;
                top:0;
                width:100%;
            }
            .border-placer{
                position:relative;
                .topline, .btmline{
                    position:absolute;
                    width:100%;
                    height:1px;
                    background:$dark_text_gold;
                    transition:top .6s ease-in-out;
                }
            }
            ul{
                font-size:0;
                li{
                    display:block;
                    text-align:center;
                    font-size:1.14rem;
                    letter-spacing: .2em;
                    margin:0;
                    padding:0;
                    a{
                        display:block;
                        padding:1rem 1rem;
                        color:$dark_text_gold;
                        transition:opacity .4s;
                        &:hover{
                            opacity:.7;
                        }
                    }
                }
            }
        }
        .page-section-content{
            color:$dark_text_gold;
            .wrap{
                position:relative;
                @include tablet{
                    padding:0 2.85rem;    
                }
            }
            .section{
                position:relative;
                margin:10rem 0 0;
                @include desktop{
                    margin:20rem 0 0;
                }
            }
            h4.section-title{
                margin:0 0 2.5rem;
            }
            .vertical-title{
                left:8%;
                top:0;
                @include tablet{
                    writing-mode:vertical-rl;
                    position:absolute;
                    padding-right:10px;
                }
                h4.section-title{
                    font-size:2.28rem;
                }
                .tc-title{
                    @include tablet{
                        letter-spacing:.7em
                    }
                }
            }
            .txt{
                @include tablet{
                    padding-left:20%;
                }
                
            }
            .style-1{
                margin:0;
            }
            .style-2{
                padding-bottom:10vh;
                @include tablet{
                    padding-bottom:16vh;
                }
                .row-1{
                    >div{
                        margin-top:3rem;
                    }
                }
                .row-2{
                    margin-top:5rem;
                }
            }
        }
        .anchor{
            @include tablet{
                top:calc(-10rem - 90px);
            }
        }
    }
}

.contactus-panel{
    width:100%;
    height:100vh;
    height:calc(var(--vh, 1vh) * 100 - 90px);
    overflow:auto;
    >div{
        @include tablet{
            height:100%;
        }
    }
    .bg-placer{
        position:absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;
        z-index:1;
        background:url('../images/banner_contactus.jpg') center center no-repeat;
        background-size:cover;
        pointer-events:none;
    }
    .dot-placer{
        position:absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;
        z-index: 1;
        pointer-events:none;
        background:url('../images/overlay.png') left top repeat;
    }
    .upper{
        position:relative;
        z-index:2;
    }
    .contact-wrap{
        position:relative;
        color:$dark_text_gold;
        @include desktop{
            height:100%;
            display:flex;
            align-items:center;
            justify-content:center;
        }
        .vertical-title{
            
            white-space:nowrap;
            
            left:0;
            top:6%;
            
            @include desktop{
                writing-mode:vertical-rl;
                position:absolute;
                padding-right:10px;
            }
            h4{
                font-size:2.28rem;
                margin:0 0 2.5rem;
            }
            h4.tc-title{
                @include tablet{
                    letter-spacing:1.5em;
                }
            }
        }
        .content-place{
            width:750px;
            max-width:100%;
            margin:auto;
            padding:0 0 50px;
            .section-title{
                margin:0 0 1rem;
                font-size:1.78rem;
            }
            .row-container{
                margin-top:3rem;
            }
            .row{
                margin-top:-2rem;
                >div{
                    margin-top:2rem;
                }
            }
            .blk{
                font-size:1.2rem;
                line-height:1.6;
                margin:2rem 0 0;
                &:first-child{
                    margin:0;
                }
                label{
                    letter-spacing:.2em;
                }
                label, .small{
                    font-size:.9rem;
                }
                p{
                    margin:0 0 .5rem;
                }
                a{
                    color:$dark_text_gold;
                    transition:opacity .4s;
                    &:hover{
                        opacity:.7;
                    }
                }
                .contact-method{
                    margin:0 0 1rem;
                    font-size:1rem;
                }
                .en{
                    letter-spacing:.1em;
                }
            }
            [class^="icon-"], [class*=" icon-"]{
                color:#ffb574;
                width:20px;
                display:inline-block;
                font-size:18px;
                vertical-align:middle;
            }
        }
    }
    .gmap-panel{
        margin:3rem 0 0;
        position:relative;
        padding-top:calc(600 / 600 * 100%);
        @include tablet{
            padding-top:calc(200 / 600 * 100%);
        }
        iframe{
            position:absolute;
            left:0;
            top:0;
            width:100%;
            height:100%;
        }
    }
}

[data-aos="gentle-up"] {
  opacity: 0;
  transition-property: transform, opacity;
  transform: translateY(30px);

  &.aos-animate {
    opacity: 1;
    transform: translateX(0);
  }
}

[data-aos="gentle-down"] {
  opacity: 0;
  transition-property: transform, opacity;
  transform: translateY(-30px);

  &.aos-animate {
    opacity: 1;
    transform: translateX(0);
  }
}

.anchor{
    display: block;
    position: relative;
    visibility: hidden;
}

.mobile-homepage-content{
    @include desktop{
        display:none;
    }
    .welcome-section{
        position:relative;
        padding:0;
        .bg-placer{
            position:absolute;
            left:0;
            top:0;
            width:100%;
            height:100%;
            opacity:.2;
            background:url('../images/homepage_sa_banner.jpg') center no-repeat;
            background-size:cover;
            z-index: 0;
            pointer-events:none;
        }
        .texting-wrap{
            display:flex;
            align-items:center;
            position:relative;
            color:$dark_text_gold;
            text-align:center;
            min-height:calc(4 / 3 * 100vw);
            .hold{
                width:100%;
                padding:50px 0;
            }
            h3{
                font-size:22px;
                letter-spacing:.2em;
                line-height:2;
                margin:0 0 2rem;
            }
            h3.tc-title{
                letter-spacing:.7em;
                text-indent:.7em;
            }
            p.ps{
                margin:2rem 0;
            }
            a.learn-more{
                display:inline-block;
                font-size:.9em;
                letter-spacing:.2em;
                color:$dark_text_gold;
                &:hover{
                    color:$dark_text_gold;
                }
            }
            .relative{
                position:relative;
            }
            .line-extend{
                position:absolute;
                left:50%;
                top:calc(100% + 20px);
                width:1px;
                height:140px;
                background:$dark_text_gold;
            }
        }
    }
    .main-service-set{
        position:relative;
        padding:0;
        .bg-placer{
            position:absolute;
            left:0;
            top:0;
            width:100%;
            height:100%;
            background-position:center;
            background-repeat:no-repeat;
            background-size:cover;
            z-index: 0;
            pointer-events:none;
        }
        .overlay-placer{
            position:absolute;
            left:0;
            top:0;
            width:100%;
            height:100%;
            z-index: 1;
            pointer-events:none;
            mix-blend-mode:multiply;
        }
        .overlay-1{
            background:rgba($dark_red, .6);
        }
        .overlay-2{
            background:rgba($dark_red2, .6);
        }
        .overlay-3{
            background:rgba($dark_red3, .6);
        }
        .dot-placer{
            position:absolute;
            left:0;
            top:0;
            width:100%;
            height:100%;
            z-index: 1;
            pointer-events:none;
            background:url('../images/overlay.png') left top repeat;
        }
        .texting-wrap{
            display:flex;
            align-items:center;
            position:relative;
            z-index:5;
            color:$dark_text_gold;
            text-align:center;
            min-height:calc(1 / 1.5 * 100vw);
            .hold{
                width:100%;
                padding:30px 0;
            }
            .tag{
                display:inline-block;
            }
            h6{
                font-size:1.3em;
                margin:.8em 0 1.2em;
                letter-spacing:.2em;
            }
            .border-btn{
                display:inline-block;
                color:$dark_text_gold;
                padding:2px 30px;
                border:1px solid $dark_text_gold;
                border-radius:50px;
                font-size:.8em;
                line-height:2;
            }
        }
    }
}

.news-panel{
    .size-limiter{
        @include mid-desktop{
            max-width:1200px;
        }
    }
    .title-label{
        
        white-space:nowrap;
        
        left:0;
        top:200px;
        z-index:1;
        @include desktop{
            writing-mode:vertical-rl;
            position:sticky;
        }
        .sticky-elem{
            top:0;
            left:0;
            padding-right:10px;
            @include desktop{
                position:absolute;
            }
            &:after{
                content:"";
                position:absolute;
                background:#871A21;
                box-shadow:0 0 1px #000000;
                @include desktop{
                    width:1px;
                    height:30vh;
                    left:50%;
                    top:calc(100% + 20px);
                }
            }
        }
        h2{
            font-size:2.5rem;
            letter-spacing:1em;
            color:$dark_text_gold;
            margin:0 0 5rem;
            @extend .title-font;
            @include mid-desktop{
                font-size:3rem;
            }
        }
    }
    .free-area{
        position:relative;
        @include desktop{
            padding-left:120px;
        }
        @include mid-desktop{
            padding-left:140px;
        }
    }
    .news-row{
        margin-top:-40px;
        margin-left:-10px;
        margin-right:-10px;
        >div{
            margin-top:40px;
            padding-left:10px;
            padding-right:10px;
        }
    }
    a.news-blk{
        display:block;
        color:$dark_text_gold;
        border:1px solid $dark_text_gold;
        font-size:1rem;
        &:hover{
            color:$dark_text_gold;
            .news-thumb img{
                transform:scale(1.08, 1.08);
            }
            i.nav{
                background:$dark_text_gold;
                color:#ffffff;
            }
        }
        .news-thumb{
            padding-top:calc(187 / 242 * 100%);
            position:relative;
            overflow:hidden;
            img{
                position:absolute;
                left:0;
                top:0;
                width:100%;
                height:100%;
                object-fit:cover;
                display:block;
                transition:transform .6s ease-in-out;
            }
        }
        .news-content{
            padding:26px;
        }
        h6.date{
            font-size:1em;
            font-weight:400;
        }
        h4.t{
            font-size:1.5em;
            font-weight:400;
            margin:1.2em 0 1.2em;
        }
        .cms{
            font-size:1em;
        }
        i.nav{
            display:inline-block;
            font-size:20px;
            border:1px solid $dark_text_gold;
            border-radius:50%;
            width:40px;
            text-align:center;
            margin:1.8em 0 0;
            float:right;
            transition:all .6s ease-in-out;
            span{
                line-height:38px;
            }
        }
    }
    .news-detail-panel{
        color:$dark_text_gold;
        font-size:1rem;
        max-width:780px;
        margin:auto;
        .info-bar{
            display:flex;
            justify-content:space-between;
            align-items:flex-start;
            padding-bottom:3rem;
            .date{
                font-size:1.5em;
                font-weight:400;
            }
            h4.t{
                font-size:2em;
                font-weight:400;
                margin:1.2em 0 0;
            }
        }
        .back-btn{
            display:inline-block;
            color:$dark_text_gold;
            flex:0 0 80px;
            text-align:right;
            transition:opacity .4s;
            &:hover{
                opacity:.7;
            }
        }
        .news-gallery{
            img{
                width:100%;
            }
            .swiper-slide{
                background:#000000;
            }
            .news-gallery-placer{
                background-position:center;
                background-repeat:no-repeat;
                background-size:contain;
                padding-top:calc(65%);
            }
            .swiper-button-next, .swiper-button-prev{
                background:none;
                color:$dark_text_gold;
                font-size:40px;
                width:auto;
                height:auto;
                margin:0;
                transform:translateY(-50%);
            }
            .swiper-button-prev{

            }
            .swiper-button-next{

            }
        }
        .detail-area{
            padding-top:3rem;
            padding-bottom:3rem;
            a{
                color:$dark_text_gold;
                transition:opacity .4s;
                &:hover{
                    opacity:.7;
                }
            }
        }
        .back-btn-bottom{
            margin:3rem 0 0;
            @include tablet{
                text-align:center;
            }
        }

    }
}

.paginate-container{
    text-align:center;
    margin:40px 0 0;
    text-align:center;
    color:$dark_text_gold;
    @include tablet{
        margin:80px 0 0;
    }
    ul{
        margin:0;
        font-size:0;
    }
    li{
        display:inline-block;
        padding:0 10px;
        margin:0;
        vertical-align:top;
        &:first-child{
            padding:0;
        }
    }
    span, a{
        font-size:1rem;
        display:inline-block;
        background:none;
        width:40px;
        height:40px;
        line-height:37px;
        font-size:14px;
        text-align:center;
        color:inherit;
        border:1px solid transparent;
        border-radius:50%;
    }
    .active a{
        border-color:$dark_text_gold;
    }
    .arrow-prev, .arrow-next{
        a{

        }
        .icon-chevron-left, .icon-chevron-right{
            font-size:24px;
        }
    }
}

.press-panel{
    text-align:center;
    .size-limiter{
        @include mid-desktop{
            max-width:1200px;
        }
    }
    h2.section-title{
        font-size:2.28rem;
        margin:0 0 5rem;
    }
    h2.tc-title{
        letter-spacing:.7em;
        text-indent:.7em;
    }
    .press-row{
        margin-top:-40px;
        @include tablet{
            margin-top:-80px;
        }
        >div{
            margin-top:40px;
            @include tablet{
                margin-top:80px;
            }
        }
    }
    .press-blk{
        display:block;
        max-width:350px;
        margin:auto;
        font-size:1rem;
        color:$dark_text_gold;
        transition:opacity .6s;
        &:hover{
            opacity:.7;
        }
        h6.date{
            font-size:1.5em;
            font-weight:400;
        }
        h6.source{
            font-size:.9em;
            font-weight:400;
            margin:.75em 0 2.5em;
            letter-spacing:.2em;
        }
        .thumb-placer{
            padding-top:calc(223 / 280 * 100%);
            position:relative;
            overflow:hidden;
            img{
                position:absolute;
                left:0;
                top:0;
                width:100%;
                height:100%;
                object-fit:cover;
                display:block;
                transition:transform .6s ease-in-out;
            }
        }
        h4.t{
            font-size:1.5em;
            font-weight:400;
            margin:1.2em 0;
            letter-spacing:.2em;
        }
    }
}

#celebrity-wedding{
    .inner-banner{
        position:fixed;
        left:0;
        top:90px;
        padding:0;
        width:100%;
        @include desktop{
            top:0;
        }
        .banner-placer{
            padding-top:calc(700 / 1400 * 100%);
            background-repeat:no-repeat;
            background-position:center;
            background-size:cover;
            opacity:.5;
        }
        .dot-layer{
            position:absolute;
            width:100%;
            height:100%;
            left:0;
            top:0;
            z-index: 1;
            background:url('../images/overlay.png') left top repeat;
        }
        .bg-layer{
            position:absolute;
            width:100%;
            height:100%;
            left:0;
            top:0;
            z-index:1;
            background:rgba(#382D21, .5);
            mix-blend-mode:multiply;
            z-index:1;
        }
        .content-overlay{
            position:absolute;
            width:100%;
            height:100%;
            left:0;
            top:0;
            z-index:1;
            display:flex;
            align-items:center;
            justify-content:center;
            padding:0 0 3.5rem;
            >div{
                text-align:center;
            }
            h4.section-title{
                margin:1rem 0 0;
                color:$dark_text_gold;
                text-indent:.2em;
                font-size:2.28rem;
            }
            h4.tc-title{
                text-indent:.7em;
            }
        }
    }
    .celebrity-wedding-list{
        background:#342424;
        position:relative;
        .sticky-menu{
            position:sticky;
            top:88px;
            padding-bottom:60px;
            z-index:5;
            .cw-submenu{
                background:#342424;
                position:absolute;
                left:0;
                top:0;
                width:100%;
                .container{
                    @media (max-width: #{$desktop-width - 1}) {
                        padding-left:0;
                        padding-right:0;
                    }
                }
                .wrapall{
                    position:relative;
                }
                .overflow-container{
                    position:relative;
                    margin:0 35px;
                    text-align:center;
                    overflow:auto;
                    -webkit-overflow-scrolling: touch;
                    &::-webkit-scrollbar {
                        display: none;
                    }
                }
                .icon-chevron-left, .icon-chevron-right{
                    position:absolute;
                    top:9px;
                    color:$dark_text_gold;
                    font-size:30px;
                    cursor:pointer;
                    z-index:5;
                }
                .icon-chevron-left{
                    left:0;
                }
                .icon-chevron-right{
                    right:0;
                }
            }
            ul{
                font-size:0;
                padding:0;
                white-space:nowrap;
                display:inline-flex;
                li{
                    display:inline-block;
                    padding:15px 20px;
                    margin:0;
                    font-size:1.1rem;
                    letter-spacing:.2em;
                    flex-grow:1;
                    text-align:center;
                    position:relative;
                    @include tablet{
                        margin-left:40px;
                        margin-right:40px;
                    }
                    a{
                        color:$dark_text_gold;
                        transition:opacity .4s, color .4s;
                        &:hover{
                            opacity:.7;
                        }
                    }
                }
                li.active a, a:hover{
                    
                }
            }
            .track{
                position:absolute;
                width:0;
                height:6px;
                bottom:0;
                transition:all .4s ease-in-out;
                &:before{
                    content:"";
                    position:absolute;
                    height:100%;
                    top:0;
                    background:#956B48;
                    width:calc(100% - 20px);
                    left:50%;
                    transform:translateX(-50%);
                }
            }
        }
        .celebrity-group{
            margin:10rem 0 0;
            &:last-child{
                padding-bottom:10rem;
            }
            .group-t{
                text-align:center;
                overflow:hidden;
                margin-bottom:8rem;
                .section-title{
                    display:inline-block;
                    position:relative;
                    font-size:1.7rem;
                    font-weight:400;
                    &:before, &:after{
                        content:"";
                        position:absolute;
                        height:1px;
                        width:999px;
                        background:$dark_text_gold;
                        top:50%;
                        transform:translateY(-50%);
                    }
                    &:before{
                        right:calc(100% + 30px);
                    }
                    &:after{
                        left:calc(100% + 30px);
                    }
                }
            }
            .celebrity-list{
                margin-top:-8rem;
                >div{
                    margin-top:8rem;
                }
                .celebrity-blk{
                    padding:0 20px;
                    text-align:center;
                    color:$dark_text_gold;
                    letter-spacing:.1em;
                    h5{
                        font-size:1.28rem;
                        margin:0 0 .3em;
                    }
                    span.caption{
                        display:block;
                        font-size:12px;
                    }
                    .border-btn{
                        display:inline-block;
                        color:$dark_text_gold;
                        padding:2px 30px;
                        border:1px solid $dark_text_gold;
                        border-radius:50px;
                        font-size:.8em;
                        line-height:2;
                        margin:2rem 0 0;
                        transition:all .4s;
                        &:hover{
                            color:#ffffff;
                            background:$dark_text_gold;
                        }
                    }
                }
            }
        }
    }
    .anchor{
        top:calc(-10rem - 90px);
    }
}

.fancybox-bg{
    background:$bg_color;
}
.fancybox-infobar{
    display:none;
}
.fancybox-slide--image{
    padding:60px;
    @include mid-desktop{
        padding:100px;
    }
}

.fancybox-caption{
    bottom:unset;
    top:0;
    background:none;
    letter-spacing:.1em;
}

#main-news-box{
    display:none;
    padding:40px;
    background:none;
    max-width:1200px;
    .fancybox-close-small{
        
    }
}

header .lang, header .function-icon, #menu .lang{
    display:none !important;
}
